@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    padding: 0;
    font-family: neue;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: black; */
  }

  @font-face {
    font-family: neue;
    src: url(./font/NeueHaasDisplayLight.ttf);
 }

 .active{
    color: #0F4E85;
    font-weight: bold;
    
 }

 .padding{
 padding: 0 10px 0 10px;
 height: 322px;
 width: 297px;
 }

 .ReactModal__Overlay {
   opacity: 0;
   transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
   opacity: 1;
}

.ReactModal__Overlay--before-close{
   opacity: 0;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}


.content{
top: 50%;
left: 50%;
right: auto;
bottom: auto;
width: 60%;
margin-right: -50%;
overflow-y: auto;
-webkit-overflow-scrolling: touch;
border-radius: 4px;
outline: none;
padding: 20px;
background: #f9f9f9;
transform: translate(-50%, -50%);
height: 100vh;

}
   
@media only screen and (max-width: 768px) {
   .width{
      width: 100%;
   }
}